import './Product.css'

const Product = (props) => {
  
  return(
    <div className="product">
      <h3>{props.title}</h3>
      <p>{props.description}</p>
    </div>
  )
}

export default Product;