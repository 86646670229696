import Slider from '../Slider/Slider';
import './Header.css'

const Header = () => {
  
  return(
    <div className="header">

      <div className="slider">
        <Slider/>
      </div>
      
      <div className="cards">
        
        <a href="/privati">
          <div className="card">
            <p>Privati</p>
          </div>
        </a>

        <a href="/aziende">
          <div className="card">
            <p>Aziende</p>
          </div>
        </a>

        <a href="/professionisti">
          <div className="card">
            <p>Professionisti</p>
          </div>
        </a>
        
      </div>
    </div>
  )
}

export default Header;