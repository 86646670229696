import './Contact.css'

const Contact = () => {

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  
  return(
    <div className="contact" id="contact">
      <h2>Vieni a trovarci</h2>
      <div className='map_container'>
        <div className='info_container'>
          <p>Il nostro ufficio è a Desenzano Del Garda</p>
          <p><a href="https://goo.gl/maps/CiQvH1sDQW782qf9A" target="_blank" rel="noopener noreferrer">Piazza Garibaldi 18, 25015, <br/>Desenzano Del Garda, Brescia,<br/> Italia</a></p>
          <p>Telefono: <a href="tel:+39 0303459053">+39 0303459053</a></p>
          <button onClick={() => openInNewTab("https://wa.me/+393663486738")}>Scrivici su Whatsapp</button>
          <button onClick={() => openInNewTab("https://www.facebook.com/teamservicedelgarda/")}>Scrivici su Facebook</button>
        </div>
        <iframe title="team_service" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11192.011103083416!2d10.534738!3d45.469748!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4781950d7ab0a57f%3A0x39cf9d436550ef28!2sTeam%20Service%20Desenzano%20del%20Garda%20di%20GAETANO%20RUSSO!5e0!3m2!1sit!2sit!4v1680772618000!5m2!1sit!2sit" />
      </div>
    </div>
  )
}

export default Contact;