import Carousel from "nuka-carousel"
import './Slider.css'

export default function Slider(){
  return(
    <Carousel
      className="carousel"
      autoplay = {true}
      autoplayInterval = {6000}
      wrapAround = {true}
      adaptiveHeight = {false}
    >
      <img alt="slider_img_1" src="https://lh3.googleusercontent.com/p/AF1QipN3CwTZVr_g9nwy6AdjQdl2fJPcELqgFNdxsciC=s1360-w1360-h1020" />
      <img alt="slider_img_2" src="https://lh3.googleusercontent.com/p/AF1QipPa-b94-IxHVL1HYFKF8eLA5fpZyuNybdmNQx8g=s1360-w1360-h1020" />
    </Carousel>
  )
}