import './Contact_Us.css'
import Contact from "../../components/Contact/Contact";

const Contact_Us = () => {
  
  return(
    <div className="contact_us">
      
      <Contact />

      <div className="social">
        <h2>I nostri Social</h2>
        <ul>
          <li>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/2021_Facebook_icon.svg/2048px-2021_Facebook_icon.svg.png" alt="facebook_logo" />
            <a href="https://www.facebook.com/teamservicedelgarda/" target="_blank" rel="noopener noreferrer">Facebook</a>
          </li>
          <li>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" alt="instagram_logo"/>
            <a href="https://www.facebook.com/teamservicedelgarda/" target="_blank" rel="noopener noreferrer">Instagram</a>
          </li>
        </ul>
      </div>  
      
    </div>
  )
}

export default Contact_Us;