import "./Main.css"
import Header from "../../components/Header/Header"
import Info from "../../components/Info/Info"
import About from "../../components/About/About"
import Contact from "../../components/Contact/Contact"
import Logos from "../../components/Logos/Logos"

const Main = () => {

  return(
    <div>
      <Header/>
      <About/>
      <Logos />
      <Contact />
    </div>
  )
}

export default Main