import './Partner.css'

const Partner = () => {

  return(
    <section className="partner_container">   
      <div className='partner'>
        <img src='/partners/kena.png' alt='kena' />
        <div>
          <h2>Kena Mobile</h2>
          <p>Kena Mobile è un operatore virtuale di telefonia mobile di proprietà di TIM e appartenente al Gruppo Telecom Italia.
            Scheda SIM di Kena Mobile Nell'agosto 2018 Kena Mobile registrò 500 000 linee mobili e a novembre dello stesso anno raggiunse un milione di linee mobili.
            Il 26 novembre 2021 Kena Mobile ha comunicato di avere 2 000 000 di clienti.
          </p>
        </div>
      </div>

      <hr/>

      <div className='partner'>
        <img src='/partners/infocert.png' alt='infocert'/>
        <div>
          <h2>Infocert</h2>
          <p>Infocert è una società leader nel settore della digitalizzazione dei processi aziendali e nella fornitura di soluzioni di identità digitale. Offre servizi di firma elettronica, conservazione a norma, autenticazione e identificazione online, garantendo sicurezza e conformità normativa alle aziende e ai professionisti che utilizzano i suoi servizi.
          </p>
        </div>
      </div>

      <hr/>

      <div className='partner'>
        <img src='/partners/namirial.png' alt='namirial'/>
        <div>
          <h2>Namirial</h2>
          <p>Namirial è un'azienda specializzata nella fornitura di soluzioni di digitalizzazione e gestione documentale. Offre servizi di firma elettronica avanzata, gestione delle identità digitali, conservazione a norma e soluzioni di automazione dei processi aziendali, aiutando le aziende a ottimizzare l'efficienza operativa e garantire la conformità normativa.
          </p>
        </div>
      </div>

      <hr/>

      <div className='partner'>
        <img src='/partners/duferco.png' alt='duferco'/>
        <div>
          <h2>Duferco Energia</h2>
          <p>Duferco Energia è una società attiva nel settore dell'energia elettrica e del gas naturale. Fornisce soluzioni energetiche personalizzate per le aziende, offrendo servizi di fornitura, consulenza e ottimizzazione dei consumi, contribuendo così a ridurre i costi energetici e promuovendo l'efficienza energetica.
          </p>
        </div>
      </div>

      <hr/>

      <div className='partner'>
        <img src='/partners/enac.png' alt='enac'/>
        <div>
          <h2>ENAC Patronato</h2>
          <p>
Il Patronato ENAC (Ente Nazionale Assistenza al Cliente) è un'organizzazione che fornisce assistenza e supporto alle persone nel settore dell'aviazione civile. Offre servizi di consulenza e assistenza per questioni legate ai diritti dei passeggeri, alle pratiche amministrative e ai reclami, garantendo un'adeguata tutela e rappresentanza degli interessi degli utenti del trasporto aereo.
          </p>
        </div>
      </div>

      <hr/>

      <div className='partner'>
        <img src='/partners/cndl.png' alt='cndl'/>
        <div>
          <h2>CAF Nazionale Del Lavoro</h2>
          <p>
Il CAF (Centro di Assistenza Fiscale) del Consiglio Nazionale del Lavoro è un servizio dedicato all'assistenza e alla consulenza fiscale per i lavoratori dipendenti e autonomi. Offre supporto nella compilazione e nell'invio delle dichiarazioni fiscali, nell'ottenimento delle detrazioni e nel chiarimento di questioni fiscali specifiche legate al mondo del lavoro, fornendo un'assistenza personalizzata e professionale.
          </p>
        </div>
      </div>

    </section>
  )
}

export default Partner;