import './App.css';
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer"
import Main from "./pages/Main/Main"
import Customer from "./pages/Customer/Customer"
import ContactUs from "./pages/Contact_Us/Contact_Us"
import Policy from "./pages/Policy/Policy"
import products from "./assets/data/products.json"

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Partner from './pages/Partner/Partner';

function App() {

  const listPrivati = products.filter( product => product.category.includes("privati"));
  const listAziende = products.filter( product => product.category.includes("aziende"));
  const listProfessionisti = products.filter( product => product.category.includes("professionisti"));

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Main/>}/>
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/partners" element={<Partner />} />
          <Route path="/privati" element={<Customer title="Per Privati" description="I CAF (Centri di Assistenza Fiscale) offrono una serie di servizi a privati e imprese. Tra i servizi principali ci sono la compilazione del modello Isee – Iseeu – Isee corrente; l’invio del modello 730 o ex Unico (modello dei redditi persone fisiche); la consulenza fiscale e previdenziale; 
          la consulenza per le pratiche contributive, disoccupazione, invalidità, maternità, pensione ecc.; la consulenza per le pratiche catastali e immobiliari; la consulenza per le pratiche di successione; la consulenza per le pratiche di separazione e divorzio; la consulenza per le pratiche di cittadinanza e permessi di soggiorno." list={listPrivati} />}/>
          <Route path="/aziende" element={<Customer title="Per Aziende" description="I Centri di Assistenza Fiscale (CAF) offrono una gamma di servizi alle aziende. Tra questi servizi, rientrano la consulenza fiscale per affrontare gli aspetti tributari dell'attività aziendale, la compilazione delle dichiarazioni fiscali come il modello 730 o UNICO, la gestione delle pratiche amministrative come la registrazione presso gli enti previdenziali e la consulenza
          previdenziale per valutare i diritti pensionistici e gestire le pratiche correlate. I CAF si occupano di fornire assistenza e consulenza professionale per semplificare e gestire gli aspetti fiscali e amministrativi delle aziende." list={listAziende} />}/>
          <Route path="/professionisti" element={<Customer title="Per Professionisti" description="I Centri di Assistenza Fiscale (CAF) forniscono una serie di servizi ai professionisti. Tra questi, offrono consulenza fiscale su questioni specifiche legate all'attività professionale, compilazione delle dichiarazioni fiscali, gestione delle pratiche amministrative come iscrizioni agli enti professionali, pratiche previdenziali e assicurative, nonché consulenza
           e assistenza per la pianificazione previdenziale e gestione delle pratiche pensionistiche. I CAF mirano a supportare i professionisti nella gestione degli aspetti fiscali, amministrativi e previdenziali correlati alla loro attività." list={listProfessionisti} />}/>
        </Routes>
        <Footer />
      </BrowserRouter>
      
    </div>
  );
}

export default App;
