import './Logos.css';

const Logos = () => {

  return (
    
    <section className='logos_container'>
      <h2>I nostri partner</h2>
      <div className='logos'>
        <a href='https://dufercoenergia.com/' target='blank' rel='noopener noreferrer'><img alt='duferco' src='/partners/duferco.png' /></a>
        <a href='https://www.infocert.it/' target='blank' rel='noopener noreferrer'><img alt='infocert' src='/partners/infocert.png' /></a>
        <a href='https://www.patronatoenac.it/' target='blank' rel='noopener noreferrer'><img alt='enac' src='/partners/enac.png' /></a>
        <a href='https://www.namirial.it/' target='blank' rel='noopener noreferrer'><img alt='namirial' src='/partners/namirial.png' /></a>
        <a href='https://www.cndl.it/' target='blank' rel='noopener noreferrer'><img alt='cndl' src='/partners/cndl.png' /></a>
        <a href='https://www.kenamobile.it/' target='blank' rel='noopener noreferrer'><img alt='kena' src='/partners/kena.png' /></a>
      </div>
    </section>

  );
}

export default Logos;
