import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import hamburger from "../../assets/hamburger_icon.svg"
import './Navbar.css'

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  return (
    <nav className="navbar">
      <div className="container">

        <div className='flex'>

          <div className="logo">
            <a href="/"><h3>Team Service Del Garda</h3></a>
          </div>

          <div className="menu-icon" onClick={handleShowNavbar}>
            <img src={hamburger} alt="hamburger_icon"/>
          </div>

        </div>

        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <NavLink to="/">HOME</NavLink>
            </li>
            <li>
              <NavLink to="/privati">PRIVATI</NavLink>
            </li>
            <li>
              <NavLink to="/aziende">AZIENDE</NavLink>
            </li>
            <li>
              <NavLink to="/professionisti">PROFESSIONISTI</NavLink>
            </li>
            <li>
              <NavLink to="/partners">PARTNER</NavLink>
            </li>
            <li>
              <NavLink to="/contact_us">CONTATTI</NavLink>
            </li>            
          </ul>
        </div>

      </div>
    </nav>
  )
}

export default Navbar