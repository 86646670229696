import './Footer.css';

const Footer = () => {

  return (
    

    <footer>
      <p>Team Service Del Garda @ 2023</p>
      <p><a href="https://goo.gl/maps/pMPZ4YEfKoA1BUXs8">Piazza Garibaldi 18, 25015, Desenzano Del Garda, Brescia, Italia</a></p>
      <p>P. IVA: 04156250989</p>
      <ul className="list">
        <li>
          <a href="/policy">Privacy Policy</a>
        </li>
        <li>
          <a href="/contact_us">Contatti</a>
        </li>
      </ul>
    </footer>

  );
}

export default Footer;
