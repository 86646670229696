import './Customer.css'
import Product from "../../components/Product/Product"
import PrivateSVG from "../../assets/doodles/private.svg"
import BusinessSVG from "../../assets/doodles/business.svg"
import ProfessionalSVG from "../../assets/doodles/professional.svg"
import { useEffect, useState } from 'react'

const Customer = (props) => {

  const [customerImg, setCustomerImg] = useState()

  useEffect(() => {
    if((props.title).includes("Privati")){
      setCustomerImg(PrivateSVG);
    }else if((props.title).includes("Aziende")){
      setCustomerImg(BusinessSVG);
    }else if((props.title).includes("Professionisti")){
      setCustomerImg(ProfessionalSVG);
    }
  }, [props])

  const listProducts = props.list.map(product => {return(
  <Product title={product.title} description={product.description} key={product.id} />)})

  return(
    <div className="customer">   
    
      <div className='headline_container'>
        <div className="headline">
          <img src={customerImg} alt={props.title} />
          <div className="headline_text">
            <h2>{props.title}</h2>
            <p>{props.description}</p>
          </div>
        </div>
      </div>
      


      <div className="products_container">
        <div className="products_grid">
          <div className="products">
            {listProducts}
          </div>
        </div>
      </div>

    </div>
  )
}

export default Customer;