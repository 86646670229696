import './About.css'

const About = () => {
  
  return(
    <div className='about_container'>
      <div className="about">
          <h2>Il CAF che non c'era</h2>
          <div className='about_body'>
            <p>Team Service Del Garda, un ufficio Caf con oltre 15 anni di esperienza a Desenzano del Garda e nelle zone circostanti. Ci dedichiamo a fornire servizi professionali e consulenza specializzata a privati, aziende e professionisti.
                <br/>
                <br/>
                Sappiamo che le questioni fiscali, previdenziali e amministrative possono essere complesse e richiedere tempo e risorse considerevoli. Il nostro obiettivo è semplificare il processo per voi, offrendovi soluzioni su misura e consulenza personalizzata per soddisfare le vostre esigenze specifiche.
                <br/>
                <br/>
                Team Service Del Garda è sinonimo di competenza, professionalità e affidabilità. Siamo qui per semplificare la gestione delle vostre questioni fiscali, previdenziali e amministrative. Contattateci oggi stesso per scoprire come possiamo aiutarvi a ottenere soluzioni personalizzate che soddisfino le vostre esigenze specifiche.</p>
            <img src="https://firebasestorage.googleapis.com/v0/b/teamservice-dd063.appspot.com/o/logo_round.png?alt=media&token=d2989179-de82-4a08-bf55-ad779b2bf3cc" />
          </div>
      </div>
    </div>
  )
}

export default About;