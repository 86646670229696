import './Policy.css'

const Policy = () => {
  
  return(
    <div className="policy">

      <h2>Privacy Policy</h2>
      <h4>TITOLARE DEL TRATTAMENTO DEI DATI</h4>
      <p>Team Service Del Garda
        <br/>
        Rappresentante Legale Gaetano Russo
        <br/>
        Piazza Garibaldi 18 Desenzano Del Garda 25015 (BS)
        <br/>
        P. Iva: 04156250989
        <br/>
        CF: RSSGTN65M10F839D
        <br/>
        Email del titolare: info@teamservicedelgarda.it
        <br/>
        Telefono: +39 030 345 9053</p>
      <h4>INFORMATIVA AI SENSI DEL REGOLAMENTO UE 2016/679 (GDPR)</h4>
      <p>In questa pagina si descrivono le modalità di gestione del sito in riferimento al trattamento dei dati personali degli utenti che lo consultano.
          La presente informativa (o “privacy policy”) è resa ai sensi dell’art. 13 del Regolamento UE 2016/679, Regolamento Europeo sulla Protezione dei 
          Dati Personali a coloro che interagiscono con i servizi web di questo sito L’informativa è resa solo per il sito di www.teamservice.com e non anche per altri siti
          web eventualmente consultati dall’utente tramite link contenuti in questo sito.</p>
      <h4>TIPOLOGIE DI DATI RACCOLTI</h4>
      <p>Fra i Dati Personali raccolti da questo Sito Web, in modo autonomo o tramite terze parti, ci potrebbero essere: Cookie, Dati di utilizzo, nome, cognome, numero di telefono, Partita IVA, ragione sociale, indirizzo, nazione, stato, provincia; email, CAP, città, varie tipologie di dati e altro.
        Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati stessi.
        I Dati Personali possono essere liberamente forniti dall’Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l’uso di questo Sito Web.
        Se non diversamente specificato, tutti i Dati richiesti da questo Sito Web sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per questo Sito Web fornire il Servizio. Nei casi in cui questo Sito Web indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività.
        Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a contattare il Titolare.
        L’eventuale utilizzo di Cookie – o di altri strumenti di tracciamento da parte di questo Sito Web o dei titolari dei servizi terzi utilizzati da questo Sito Web, ove non diversamente precisato, ha la finalità di fornire il Servizio richiesto dall’Utente, oltre alle ulteriori finalità descritte nel presente documento e nella Cookie Policy, se disponibile.
        L’Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante questo Sito Web e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi.</p>
      <h4>DESTINATARI DEI DATI</h4>
      <p>Nei limiti pertinenti alle finalità di trattamento indicate, i Suoi dati potranno essere comunicati a eventuali terzi soggetti, nominati Responsabili e Incaricati dal Titolare del Trattamento. I Suoi dati non saranno in alcun modo oggetto di diffusione.</p>
      <h4>Modalità e luogo del trattamento dei Dati raccolti</h4>
      <p>Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali. Il trattamento dei Dati viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di questo Sito Web (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento.
        I dati personali possono essere comunicati a soggetti terzi che svolgono attività necessarie per l’erogazione dei servizi offerti dal Sito (a titolo esemplificativo, l’analisi del funzionamento del Sito medesimo) che tratteranno i dati in qualità di responsabili e/o in qualità di autorizzati che agiscono sotto l’autorità del Titolare e del Responsabile ovvero quali soggetti espressamente designati al trattamento dei dati nei termini previsti dal GDPR e dalla normativa nazionale di adeguamento alle disposizioni del GDPR come, per esempio, fornitori di servizi informatici e/o di archiviazione o di altri servizi di natura tecnico/organizzativa) oppure dipendenti e collaboratori dello Studio. Le informazioni dell’utente potranno, inoltre, essere comunicate a terzi al fine di adempiere gli obblighi previsti dalla legge, dalla normativa europea o nazionale vigente e consentire la difesa in giudizio dello Studio (per esempio nel caso di violazioni da parte di utenti della rete). I dati personali conferiti non saranno soggetti a diffusione.</p>
      <h4>TRASFERIMENTO DEI DATI ALL’ESTERO</h4>
      <p>I dati raccolti non saranno oggetto di trasferimento all’estero.</p>
      <h4>PERIODO DI CONSERVAZIONE</h4>
      <p>I dati raccolti verranno conservati per un arco di tempo non superiore al conseguimento delle finalità per le quali sono trattati (“principio di limitazione della conservazione”, art.5, GDPR) o in base alle scadenze previste dalle norme di legge. La verifica sulla obsolescenza dei dati conservati in relazione alle finalità per cui sono stati raccolti viene effettuata periodicamente.</p>
      <h4>DIRITTI DELL'INTERESSATO</h4>
      <p>L’interessato ha sempre diritto a richiedere al Titolare l’accesso ai Suoi dati, la rettifica o la cancellazione degli stessi, la limitazione del trattamento o la possibilità di opporsi al trattamento, di richiedere la portabilità dei dati, di revocare il consenso al trattamento facendo valere questi e gli altri diritti previsti dal GDPR tramite semplice comunicazione al Titolare. L‘interessato può proporre reclamo anche a un’autorità di controllo.</p>
      <h4>OBBLIGATORIETÀ O MENO DEL CONFERIMENTO DATI</h4>
      <p>La informiamo che il conferimento dei dati è per alcuni campi facoltativo e per altri obbligatorio e il mancato conferimento dei dati obbligatori comporta il NON invio del form.</p>
      <h4>OBBLIGATORIETÀ DEL CONSENSO</h4>
      <p>La prestazione del consenso al trattamento dati tramite apposita spunta è facoltativa, ma indispensabile per procedere con l’invio del form.</p>

      <h2>Cookie Policy</h2>

      <h4>CHE COSA SONO I COOKIES?</h4>
      <p>I Cookie sono piccoli file di testo costituiti da porzioni di codice che vengono salvati sul vostro computer durante la visita a specifici siti internet. Essi memorizzano dati che ritrasmettono al sito in occasione di visite successive. Alcune delle finalità di installazione dei Cookie potrebbero, inoltre, necessitare del consenso dell’utente. Di seguito troverai tutte le informazioni sui cookies installati attraverso questo sito e le indicazioni necessarie su come gestirli in base alle tue preferenze.</p>
      <h4>CHE TIPOLOGIE DI COOKIES CI SONO?</h4>
      <h5>Cookies di navigazione</h5>
      <p>Questo sito internet utilizza Cookie per salvare la sessione dell’Utente e per svolgere altre attività strettamente necessarie al funzionamento dello stesso.</p>
      <h5>Cookies di Terze Parti</h5>
      <p>I servizi elencati di seguito potrebbero raccogliere statistiche in forma aggregata e potrebbero non richiedere il consenso dell’Utente o potrebbero essere gestiti direttamente dal Titolare – a seconda di quanto descritto – senza l’ausilio di terzi. Possono inoltre compiere attività di tracciamento dell’Utente. Dal momento che l’installazione di Cookie e di altri sistemi di tracciamento operata da terze parti tramite i servizi utilizzati all’interno di questa Applicazione non può essere tecnicamente controllata dal Titolare, ogni riferimento specifico a Cookie e sistemi di tracciamento installati da terze parti è da considerarsi indicativo. Per ottenere informazioni complete, consulta la privacy policy dei servizi terzi elencati in questo documento.</p>

      <h4>ELENCO DEI COOKIES PRESENTI SU QUESTO SITO</h4>
      <table border="1" cellspacing="3" cellpadding="3" align="left">
        <thead>
          <tr>
            <th>NOME</th>
            <th>TIPO E FUNZIONALITÀ</th>
            <th>LINK PER MAGGIORI INFORMAZIONI</th>
          </tr>
        </thead>
          <tbody>
            <tr>
              <td>Google Analytics</td>
              <td>Cookie di terze parti che permette l’analisi in forma aggregata delle visite degli utenti per fini statistici e informativi</td>
              <td><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank" rel="noopener noreferrer">Link</a></td>
            </tr>
            <tr>
              <td>CookieConsent</td>
              <td>Memorizza lo stato del consenso ai cookies dell’utente per il dominio corrente</td>
              <td><a href="https://www.npmjs.com/package/react-cookie-consent" target="_blank" rel="noopener noreferrer">Link</a></td>
            </tr>
          </tbody>
      </table>

      <h4>COME DISABILITARE I COOKIES</h4>
      <p>E' possibile gestire le preferenze dei cookies anche tramite il tuo browser. Se non conosci quale browser e quale versione stai utilizzando clicca su "Aiuto" nella finestra del browser in alto. Controlla poi come accedere alla pagina di gestione dei cookies.
        <br/>
        <br/>
        Alcune linee guida dei browser più famosi:
        <br/>
        <br/>
        Google Chrome: https://support.google.com/chrome/answer/95647?hl=it&hlrm=en
        <br/>
        Internet Explorer: https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d
        <br/>
        Safari: https://support.apple.com/it-it
        <br/>
        Mozilla Firefox: https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop?redirectslug=Attivare+e+disattivare+i+cookie&redirectlocale=it
        <br/>
        <br/>
        Si informa che l’Utente può avvalersi di Your Online Choices, attraverso il quale è possibile gestire le preferenze di tracciamento della maggior parte degli strumenti pubblicitari. Si consiglia agli Utenti di utilizzare tale risorsa in aggiunta alle informazioni fornite dal presente documento.</p>

    </div>  
  )
}

export default Policy;